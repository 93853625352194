import React, { useContext } from 'react';
import { AppContext, AppContextProps } from '../../store/context';
import { Link } from 'gatsby';
import { Media } from '../Media/Media';
import LogoImg from '../../assets/images/logo-loop3.svg';
import LinkedinIcon from '../../assets/icons/linkedin.inline.svg';
import GetInTouch from './GetInTouch/GetInTouch';

import './Footer.scss';
import { MENU_ITEMS, SOCIAL_MEDIA_LINKS } from '../../mock/SiteData';

const Footer = () => {
    const { currentPage } = useContext(AppContext) as AppContextProps;
    const showGetInTouch = currentPage !== 'Home' && currentPage !== '404';

    return (
        <footer className={`footer ${showGetInTouch ? 'footer--dark' : null}`}>
            <div className="footer__wrapper">
                {showGetInTouch ? <GetInTouch /> : null}
                <div className="footer__content-wrapper">
                    <div className="footer__logo-and-icons-wrapper">
                        <div className="footer__logo">
                            <Media
                                source={LogoImg}
                                className={'footer__logo-image'}
                                alt={'loop3-logo'}
                            />
                        </div>
                        <div className="footer__icons-wrapper">
                            <ul className="footer__icons-list">
                                {/* <li className="footer__icon">
                                    <a href="/" target="_blank">
                                        <FacebookIcon />
                                    </a>
                                </li>
                                <li className="footer__icon">
                                    <a href="/" target="_blank">
                                        <InstagramIcon />
                                    </a>
                                </li> */}
                                <li className="footer__icon">
                                    <a
                                        href={SOCIAL_MEDIA_LINKS.linkedIn}
                                        target="_blank"
                                        aria-label="Check our Linkedin page">
                                        <LinkedinIcon />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="footer__links-wrapper">
                        <div className="footer__first-links">
                            <ul>
                                {MENU_ITEMS.map(i => (
                                    <li className="footer__link" key={i.id}>
                                        <Link to={`${i.url}`}>{i.label}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {/* <div className="footer__second-links"> TODO: remove when legal and privacy policy exists
                            <ul>
                                <li className="footer__link">
                                    <a href="/" target="_blank">
                                        LEGAL
                                    </a>
                                </li>
                                <li className="footer__link">
                                    <a href="/" target="_blank">
                                        PRIVACY POLICY
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                </div>
                <div className="footer__copyright-wrapper">
                    <p className="footer__copyright-text">
                        Loop3 Studio / 2023(C) / All rights reserved
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
