import { Link } from 'gatsby';
import React, { FC, HTMLAttributeAnchorTarget } from 'react';

import './Button.scss';

type ButtonType = 'anchor' | 'button' | 'link';

export type ButtonProps = {
    tag: ButtonType;
    children?: React.ReactNode;
    className?: string;
    href?: string;
    target?: HTMLAttributeAnchorTarget;
    isLoading?: boolean;
    onClick?:
        | ((
              event: React.MouseEvent<
                  HTMLDivElement | HTMLAnchorElement | HTMLButtonElement,
                  MouseEvent
              >,
          ) => void)
        | undefined;
};

const Button: FC<ButtonProps> = props => {
    const { onClick, className, href, tag, isLoading, target } = props;

    return (
        <>
            {tag === 'anchor' && (
                <a
                    href={href}
                    className={`btn ${className} ${isLoading ? 'btn--loading' : ''}`}
                    target={target ?? '_self'}>
                    <div className="btn__wrapper">{props.children}</div>
                </a>
            )}

            {tag === 'button' && (
                <button
                    onClick={onClick}
                    className={`btn ${className} ${isLoading ? 'btn--loading' : ''}`}>
                    {props.children}
                </button>
            )}

            {tag === 'link' && href && (
                <Link
                    to={href}
                    className={`btn ${className} ${isLoading ? 'btn--loading' : ''}`}
                    target={target ?? '_self'}>
                    <div className="btn__wrapper">{props.children}</div>
                </Link>
            )}
        </>
    );
};

Button.defaultProps = {};

export default Button;
