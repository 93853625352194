import React, { FC, useState } from 'react';
import { Link } from 'gatsby';
import { easeFunction } from '../../../utils/variables';
import SplitText from 'react-pose-text';

import './Cta.scss';

export type CtaProps = {
    className?: string;
    onClick?: () => void;
};

const TextHover = {
    init: {
        y: 0,
    },
    hover: {
        y: 0,
        transition: ({ charIndex }: { charIndex: number }) => ({
            type: 'keyframes',
            values: [0, -5, 0],
            duration: 450,
            easings: easeFunction(),
            delay: charIndex * 20,
        }),
    },
};

const Cta: FC<CtaProps> = props => {
    const [hover, setHover] = useState(false);

    return (
        <Link
            to="/#contact-us"
            className={`cta ${props.className ? props.className : ''}`}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
                if (props.onClick) props.onClick();
            }}>
            <SplitText charPoses={TextHover} pose={hover ? 'hover' : 'init'}>
                Let's Talk
            </SplitText>
        </Link>
    );
};

export default Cta;
