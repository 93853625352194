import React, { FC, useRef, useEffect, useState, useCallback } from 'react';
import gsap, { Power2 } from 'gsap';
import classNames from 'classnames';
import { Media } from '../Media/Media';
import Menu from './Menu/Menu';
import LogoImg from '../../assets/images/logo-loop3-negative.svg';
import CloseIcon from '../../assets/icons/close.inline.svg';
import LinkedinIcon from '../../assets/icons/linkedin.inline.svg';
import Cta from '../Navbar/Cta/Cta';
import { Link } from 'gatsby';

import './Drawer.scss';
import { MENU_ITEMS, SOCIAL_MEDIA_LINKS } from '../../mock/SiteData';

export type DrawerProps = {
    active: boolean;
    onClose?: () => void;
};

const Drawer: FC<DrawerProps> = props => {
    const [closing, setClosing] = useState(false);
    const drawerRef = useRef<HTMLDivElement>(null);
    const drawerTl = useRef<any>(null);
    const overlayTl = useRef<any>(null);
    const footerItemTl = useRef<any>(null);
    const q = gsap.utils.selector(drawerRef);

    useEffect(() => {
        const displayHeight = () => {
            const doc = document.documentElement;
            doc.style.setProperty('--display-height', `${window.innerHeight}px`);
        };
        window.addEventListener('resize', displayHeight);
        displayHeight();

        return () => {
            window.removeEventListener('resize', displayHeight);
        };
    }, []);

    useEffect(() => {
        overlayTl.current = gsap.timeline({ paused: true }).to(q('.drawer-overlay'), {
            opacity: 1,
            duration: 0.55,
            ease: Power2.easeInOut,
        });

        drawerTl.current = gsap
            .timeline({ paused: true })
            .to(q('.drawer'), {
                x: '-100%',
                duration: 1.1,
                ease: Power2.easeInOut,
                onReverseComplete: () => {
                    setClosing(false);
                    document.querySelector('html')?.classList.remove('scroll-hidden');
                    // Scroll up 1 pixel to show navbar animation
                    window.scrollTo(0, window.pageYOffset - 1);
                },
                onComplete: () => {
                    document.querySelector('html')?.classList.add('scroll-hidden');
                },
            })
            .to(q('.drawer__logo'), {
                opacity: 1,
                duration: 0.55,
                delay: -0.2,
                ease: Power2.easeInOut,
            })
            .to(q('.drawer__actions'), {
                opacity: 1,
                duration: 0.55,
                delay: -0.45,
                ease: Power2.easeInOut,
            });

        footerItemTl.current = gsap.timeline({ paused: true }).to(q('.footer-item'), {
            opacity: 1,
            duration: 0.55,
            delay: 1.4,
            ease: Power2.easeInOut,
        });
    }, []);

    useEffect(() => {
        if (props.active) {
            overlayTl.current.play();
            drawerTl.current.play();
            footerItemTl.current.play();
        }
    }, [props.active]);

    const onClose = useCallback(() => {
        if (props.onClose) {
            props.onClose();
            drawerTl.current.reverse(0, false);
            footerItemTl.current.reverse();
            overlayTl.current.reverse();
        }
    }, []);

    return (
        <div ref={drawerRef}>
            <div className="drawer-overlay"></div>
            <div
                role="presentation"
                className={classNames('drawer', {
                    'drawer--active': props.active,
                    'drawer--closing': closing,
                })}>
                <div className="drawer__wrapper">
                    <div className="drawer__header">
                        <Link
                            to={'/'}
                            style={{ display: 'block' }}
                            onClick={() => {
                                setClosing(true);
                                onClose();
                            }}>
                            <Media source={LogoImg} className="drawer__logo" alt="loop3-logo" />
                        </Link>
                        <div className="drawer__actions">
                            <Cta
                                className="drawer__cta cta__black"
                                onClick={() => {
                                    onClose();
                                }}
                            />
                            <div
                                className="drawer__close"
                                onClick={() => {
                                    setClosing(true);
                                    onClose();
                                }}>
                                <CloseIcon
                                    className={classNames('drawer__cross', {
                                        'drawer--closing': closing,
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="drawer__body">
                        <Menu
                            active={props.active}
                            items={MENU_ITEMS}
                            onClick={() => {
                                setClosing(true);
                                onClose();
                            }}
                        />
                    </div>
                    <div className="drawer__footer">
                        <ul className="drawer__footer-list">
                            <li className="drawer__footer-item footer-item">
                                <a
                                    href={SOCIAL_MEDIA_LINKS.linkedIn}
                                    target="_blank"
                                    className="footer-item__icon"
                                    aria-label="Check our Linkedin page">
                                    <LinkedinIcon />
                                </a>
                            </li>
                        </ul>
                        <div className="drawer__footer-item footer-item footer-item--copyright">
                            <a className="footer-item__email" href="mailto:info@loop3studio.com">
                                info@loop3studio.com
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Drawer.defaultProps = {
    active: false,
};

export default Drawer;
