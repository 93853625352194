import React from 'react';
// CASE STUDY
import heroBgImage from '../assets/images/case-study-hero-desk.webp';
import reactLogo from '../assets/images/technologies/react.svg';
import gatsbyLogo from '../assets/images/technologies/gatsby.webp';
import laravelLogo from '../assets/images/technologies/laravel.webp';
import vueLogo from '../assets/images/technologies/vue.webp';
import wordpressLogo from '../assets/images/technologies/wordpress.webp';
import springLogo from '../assets/images/technologies/spring.webp';
import greensockLogo from '../assets/images/technologies/greensock.webp';
import javascriptLogo from '../assets/images/technologies/javascript.webp';
import sassLogo from '../assets/images/technologies/sass.webp';
import { ServiceProps } from '../components/Services/Service/Service';

// HISTORY SLIDER
import { HistorySlide } from '../sections/HistorySlider/HistorySlider';
import ArteUnicoImg from '../assets/images/brands/ArteUnico.webp';
import ComCastImg from '../assets/images/brands/ComCast.svg';
import RofexImg from '../assets/images/brands/Rofex.svg';
import TheNewYorkTimesLogoImg from '../assets/images/brands/TheNewYorkTimesLogo.webp';
import MadCaveLogo from '../assets/icons/madcave-logo.svg';
import AquariumLogo from '../assets/icons/aquarium.svg';
import GetRealLogo from '../assets/icons/got-milk.svg';
import AnthosLogo from '../assets/icons/anthos-logo.svg';

// ABOUT
import AboutHeroImg from '../assets/images/about-hero-image.webp';
import AboutHeroBgImg from '../assets/images/about-hero-background.webp';
import { MenuItem } from '../components/Drawer/Menu/Menu';

export type CaseStudyProps = {
    id: string;
    name: JSX.Element | string;
    shortTitle?: string;
    field: string;
    description: string;
    image: string;
    imgAlt: string;
    hero: {
        image: string;
        title: string;
        heroDescriptionText: string;
        bgImg: string;
        projectSection: {
            delivery: { header: string; p: string };
            duration: { header: string; p: string };
        };
    };
    link: string;
    externalLink?: string;
    pageDescription: string;
    technologies: {}[];
    technologyDescription: string;
    descriptionItems: {
        id: string;
        title: string;
        image1?: string;
        image2?: string;
        description: string;
    }[];
    sliderImage: string;
};

export const CaseStudyData: CaseStudyProps[] = [
    {
        id: 'nytimes',
        description:
            'We developed a brand new site for The New York Times – Media Kit, a place where marketers can look for the different advertising options to publish on The New York Times',
        field: 'UX/UI Design - Web Dev.',
        link: '/case-studies/nytimes',
        name: 'NY Times\nMedia Kit',
        shortTitle: 'NY Times',
        image: '/images/case-studies/nytimes/cover.2.webp',
        sliderImage: '/images/case-studies/nytimes/slider.webp',
        imgAlt: 'NY Times',
        externalLink: 'https://advertising.nytimes.com/',
        hero: {
            image: '/images/case-studies/nytimes/main-hero.webp',
            title: 'NYT Media Kit',
            heroDescriptionText:
                'We developed a brand new site for The New York Times – Media Kit, a place where marketers can look for the different advertising options to publish on The New York Times',
            bgImg: heroBgImage,
            projectSection: {
                delivery: {
                    header: 'Delivery',
                    p: 'UX/UI Design - Web Dev.',
                },
                duration: {
                    header: 'duration',
                    p: '10 weeks',
                },
            },
        },
        pageDescription: 'CASE STUDY',
        technologies: [{ image: gatsbyLogo, id: 'tn-1' }],
        technologyDescription: '',
        descriptionItems: [
            {
                id: 'pg-1',
                title: 'The Client',
                image1: '/images/case-studies/nytimes/mock-1.webp',
                image2: '/images/case-studies/nytimes/mock-2.webp',
                description:
                    'The New York Times is one of the most prestigious newspapers in North America and maybe in the World with more than 9.17 Million paid subscribers.',
            },
            {
                id: 'pg-2',
                title: 'The Need',
                image1: '/images/case-studies/nytimes/new-york-times-scrolling.gif',
                description:
                    'As it’s well known one of the biggest revenue streams for Newspapers is advertising, things were easier early on when you had just one advertising format, now with the rise of digital the different types of advertising has grown exponentially.\n\nSo the NYT needed a site to showcase all the different types of advertising they offer so companies could pick the right one for them.',
            },
            {
                id: 'pg-3',
                title: 'The Solution',
                description:
                    'We work side-by-side with the Fictive Kin and The New York Times design team to create a smooth experience focused on performance.\n\nAfter exploring a few options we knew that GatsbyJS was the way to go, a lightning-fast experience that has an exceptional SEO ranking and all the benefits of react but with server-side rendering.\n\nBeing able to manage the content in real-time is a new standard nowadays, so we integrated the FrontEnd with Contentful CMS to give the client an easy way to manage the content behind the scenes.',
            },
        ],
    },
    {
        id: 'get-real',
        description:
            'The creators of the iconic ‘got milk?’ brand, the California Milk Processor Board (CMPF) wants everyone to get real and is launching the Get Real campaign to promote the benefits of being real in all aspects of life, including drinking real milk.',
        field: 'Web & WordPress Dev.',
        link: '/case-studies/gotmilk',
        name: 'Get Real',
        image: '/images/case-studies/getreal/cover.webp',
        sliderImage: '/images/case-studies/getreal/slider.webp',
        imgAlt: 'Get Real',
        externalLink: 'https://get-real-california.gotmilk.com/',
        hero: {
            image: '/images/case-studies/getreal/main-hero.webp',
            title: 'Get real',
            heroDescriptionText:
                'The creators of the iconic ‘got milk?’ brand, the California Milk Processor Board (CMPF) wants everyone to get real and is launching the Get Real campaign to promote the benefits of being real in all aspects of life, including drinking real milk.',
            bgImg: heroBgImage,
            projectSection: {
                delivery: {
                    header: 'Delivery',
                    p: 'Web & WordPress Development',
                },
                duration: {
                    header: 'Duration',
                    p: '8 weeks',
                },
            },
        },
        pageDescription: 'CASE STUDY',
        technologies: [
            { image: gatsbyLogo, id: 'tn-1' },
            { image: springLogo, id: 'tn-2' },
            { image: wordpressLogo, id: 'tn-3' },
        ],
        technologyDescription: '',
        descriptionItems: [
            {
                id: 'pg-1',
                title: 'The Client',
                image1: '/images/case-studies/getreal/mock-1.webp',
                image2: '/images/case-studies/getreal/mock-2.webp',
                description:
                    'The California Milk Processor Board is a nonprofit marketing board funded by California dairy processors and administered by the California Department of Food and Agriculture. It is most well known for its Got Milk? advertising campaign.',
            },
            {
                id: 'pg-2',
                title: 'The Need',
                image1: '/images/case-studies/getreal/scroll.gif',
                description:
                    'They came up with an idea for their "Got Milk?" campaign that needed to be implemented in less than two months. Consisted of a California map that shows all social media posts from Tiktok, Instagram, and Twitter that includes their campaign hashtags. They wanted that solution to be fully configurable, from content creators, to cities and to hashtags that were used to pull up the posts from social media.',
            },
            {
                id: 'pg-3',
                title: 'The Solution',
                description:
                    "It has three important applications. In the first place, we built a Spring Java server app to integrate with Data365 API to pull up the posts by location, hashtags, and content creators' usernames.\n\nWe parse that data and expose it by an API to be pulled from a Gatsby.js application that was running inside a headless WordPress site to make it fully customizable. This front-end application integrates with the Mapbox library to show the California map so the user will be able to zoom in and out and navigate through the different cities to see the posts that match the campaign hashtags.\n\nThe client is able to modify some Gatsby data from WordPress along with some Spring Java Middleware configuration, like hashtags to be pulled, location, and content creators' usernames along with the capability to add or remove posts that are pulled from the social media.",
            },
        ],
    },
    {
        id: 'ahora-vuelvo-mama',
        description:
            'Ahora Vuelvo Mama is a Spanish company whose main objective is to revolutionize the concept of one-day travels. During 2019 they reached more than 8000 passengers.',
        field: 'UX/UI Design - Web Dev.',
        link: '/case-studies/ahoravuelvomama',
        name: 'Ahora Vuelvo\nMama',
        image: '/images/case-studies/ahoravuelvomama/main-hero.webp',
        sliderImage: '/images/case-studies/ahoravuelvomama/slider.webp',
        imgAlt: 'Ahora Vuelvo Mama',
        externalLink: 'https://ahoravuelvomama.es',
        hero: {
            image: '/images/case-studies/ahoravuelvomama/main-hero.webp',
            heroDescriptionText:
                'When the client came to us with the idea of creating a site to disrupt the one-day-travel booking system we were right on!',
            title: 'AVM App',
            bgImg: heroBgImage,
            projectSection: {
                delivery: {
                    header: 'Delivery',
                    p: 'UX/UI Design & Web Development',
                },
                duration: {
                    header: 'duration',
                    p: '16 weeks',
                },
            },
        },
        technologies: [
            { image: vueLogo, id: 'tn-1' },
            { image: laravelLogo, id: 'tn-2' },
        ],
        technologyDescription: '',
        pageDescription: 'CASE STUDY',
        descriptionItems: [
            {
                id: 'pg-1',
                title: 'The Client',
                image1: '/images/case-studies/ahoravuelvomama/mock-2.webp',
                image2: '/images/case-studies/ahoravuelvomama/mock-3.webp',
                description:
                    'Ahora Vuelvo Mama is a Spanish company whose main objective is to revolutionize the concept of one-day travels. During 2019 they reached more than 8000 passengers.',
            },
            {
                id: 'pg-2',
                title: 'The Need',
                image1: '/images/case-studies/ahoravuelvomama/mock-4.new.webp',
                description:
                    'The idea was born from one of its founders after his girlfriend gave him a round trip to Paris in the same day. In the Spanish market there was no player that offered integral solutions for this kind of one-day trips.\n\nThe business model was then worked on and refined as a final degree project until they needed to make it a reality. It was at that moment when they came to us with the need to think, plan, design and develop their custom web platform allowing users to book same-day round-trip flights, ending the limitations of their city.',
            },
            {
                id: 'pg-3',
                title: 'The Solution',
                description:
                    'We worked alongside Rica Design during the whole design and development stages with the goal of creating a straightforward, simple, yet entertaining tool that reflects the passion of sharing.\n\nAs for the Frontend, we decided to create a PWA with Vue.js obtaining as a result an extremely lightweight and flexible application. On the backend side, we developed an MVC application usign Laravel.',
            },
        ],
    },
    {
        id: 'gamer-brain',
        description:
            'Your website is a part of your identity. For this project, we made ideas understandable and actionable through web design. Find out how we did it.',
        field: 'Web Development',
        link: '/case-studies/gamerbrain',
        name: 'HP Gamer\nBrain',
        image: '/images/case-studies/gamerbrain/main-hero.webp',
        sliderImage: '/images/case-studies/gamerbrain/slider.webp',
        imgAlt: 'HP Gamer Brain',
        externalLink: 'https://www.omen.com/us/en/gamer-brain.html',
        hero: {
            image: '/images/case-studies/gamerbrain/main-hero.webp',
            title: 'Gamer Brain',
            heroDescriptionText:
                "We can't say NO to a Gaming website with amazing animations and 3D art! Explore all the cool stuff we did for HP.",
            bgImg: heroBgImage,
            projectSection: {
                // TODO: falta data
                delivery: {
                    header: 'Delivery',
                    p: 'Web Development',
                },
                duration: {
                    header: 'duration',
                    p: '8 weeks',
                },
            },
        },
        pageDescription: 'CASE STUDY',
        technologies: [
            { image: reactLogo, id: 'tn-1' },
            { image: greensockLogo, id: 'tn-2' },
        ],
        technologyDescription: '',
        descriptionItems: [
            {
                id: 'pg-1',
                title: 'The Client',
                image1: '/images/case-studies/gamerbrain/mock-1.webp',
                image2: '/images/case-studies/gamerbrain/mock-2.webp',
                description:
                    'HP (Hewlett-Packard) is a worldwide known company, mainly due to their great electronic products, that ranges from desktop pcs to printers and more.',
            },
            {
                id: 'pg-2',
                title: 'The Need',
                image1: '/images/case-studies/gamerbrain/scroll.gif',
                description:
                    'This time, the request came from one of HPs business units called OMEN, they focus mainly on gaming pcs and peripherals. They had a realy ambitous idea for a publicitary campaign under the concept of “Gamer Brains”, wich consisted on a quiz with a series of questions that at the end determined, based on your response, what kind of gamer brain you have, It was a really fun a nice concept.',
            },
            {
                id: 'pg-3',
                title: 'The Solution',
                description:
                    'So we got to work, for this project we decided on using React as the tool for the job, not only for our solid experience working with it, but also for the great DOM interaction it offers, since this was going to be a project that realied heavily on animations and playing around with the DOM elements.\n\nRegarding the animation side of things, we used GreenSock, mainly because we consider that when it comes to animating a website there really isn’t a better solution than what this library offers. Not just from the ease of use, that allows you to really let your imagination fly without the concern of being something unattainable, but also, from the performance side of things. It really is a great library and one we whole heartedly recommend to anyone doing a project that realies heavily on storytelling or animations.\n\nWith these tools at hand we managed to create something that not just us, but the client too, are really proud of.',
            },
        ],
    },
    {
        id: 'dr-b',
        description:
            'Some platforms not always regard UX as a priority. In this project we modifyed an outdated UI, to simplify it, make it more readable, and usable. Find out how!',
        field: 'Web Development',
        link: '/case-studies/drbubba',
        name: 'Dr. Bubba',
        image: '/images/case-studies/drbubba/main-hero.new.webp',
        sliderImage: '/images/case-studies/drbubba/slider.webp',
        imgAlt: 'Dr. Bubba',
        externalLink: 'https://drbubba.com',
        hero: {
            image: '/images/case-studies/drbubba/main-hero.new.webp',
            title: 'Dr. Bubba',
            heroDescriptionText:
                'Some platforms not always regard UX as a priority. In this project we modifyed an outdated UI, to simplify it, make it more readable, and usable. Find out how!',
            bgImg: heroBgImage,
            projectSection: {
                delivery: {
                    header: 'Delivery',
                    p: 'Web Development',
                },
                duration: {
                    header: 'duration',
                    p: '6 weeks',
                },
            },
            image: '/images/case-studies/drbubba/main-hero.new.webp',
        },
        pageDescription: 'CASE STUDY',
        technologies: [
            { image: javascriptLogo, id: 'tn-1' },
            { image: sassLogo, id: 'tn-2' },
        ],
        technologyDescription: '',
        descriptionItems: [
            {
                id: 'pg-1',
                title: 'The Client',
                image1: '/images/case-studies/drbubba/mock-1.webp',
                image2: '/images/case-studies/drbubba/mock-2.webp',
                description:
                    'Dr. B enables online patients to get and fill the everyday prescriptions they depend on from the commodity of their home, while expanding access to care for those who need it most.',
            },
            {
                id: 'pg-2',
                title: 'The Need',
                image1: '/images/case-studies/drbubba/mock-3.webp',
                description:
                    'All this medical data that goes through Dr. B is analyzed by health professionals in an ERP platform called canvas medical, unluckily, those professionals seemed to be struggling with the platformand in need of extensive training for them to use it effectively.\n\nSo we decided we needed to make it easier to use, without compromising on functionality or waiting for them to get a new update out.',
            },
            {
                id: 'pg-3',
                title: 'The Solution',
                description:
                    'As a result of that need, we started quite an interesting and challenging project, where using a chrome extension we injected JS and CSS into the page effectively altering both the UI and the functionality.\n\nThe changes ranged between the restyling of an almost unreadable table into a clean and useful one, to hiding away a confusing navigation and displaying all the actions for one patient file on a single card. We even expanded on those actions to make the professionals need less clicks and input less data, for example, most of the questionnaires now autocomplete with the available data, without the need for the user to type in everything.\n\nIt was a very challenging project mainly due to the fact that we had to interact with a react application that was running completely separate from the extension, with render cycles, asynchronous state changes and events that we needed to wait for in a completely separate runtime. But, thanks to our amazing team, we managed to figure it out and right now the platform needs next to no training to be used effectively.\n We could say “problem solved” ;)',
            },
        ],
    },
    {
        id: 'time-2-talk',
        description:
            'Can your website be a tool to help you fulfill your business goals? In this project, we will show you how a website can not only look great but also collaborate in achieving all sorts of business goals.',
        field: 'Web Design & WordPress Dev.',
        link: '/case-studies/time2talk',
        name: 'Time2Talk',
        image: '/images/case-studies/time2talk/main-hero.webp',
        sliderImage: '/images/case-studies/time2talk/slider.webp',
        imgAlt: 'Time2Talk',
        externalLink: 'https://time2talk.app/',
        hero: {
            image: '/images/case-studies/time2talk/main-hero.webp',
            title: 'Time2Talk',
            heroDescriptionText:
                "If someone comes to you with an app that will help people learn your native language wouldn't you help them? Keep reading to learn how we helped Time2Talk meet its goals!",
            bgImg: heroBgImage,
            projectSection: {
                delivery: {
                    header: 'Delivery',
                    p: 'Web design & WordPress Development',
                },
                duration: {
                    header: 'duration',
                    p: '16 weeks',
                },
            },
        },
        pageDescription: 'CASE STUDY',
        technologies: [
            { image: reactLogo, id: 'tn-1' },
            { image: wordpressLogo, id: 'tn-2' },
        ],
        technologyDescription: '',
        descriptionItems: [
            {
                id: 'pg-1',
                title: 'The Client',
                image1: '/images/case-studies/time2talk/mock-1.webp',
                image2: '/images/case-studies/time2talk/mock-2.webp',
                description:
                    "Time2Talk is a pioneer in offering high schools, universities and businesses the ability to improve participants' confidence, fluency and cultural proficiency in Spanish by allowing participants to instantly choose from a variety of friendly Spanish-speaking coaches to help them improve in a one-on-one conversation style format.",
            },
            {
                id: 'pg-2',
                title: 'The Need',
                image1: '/images/case-studies/time2talk/mock-3.webp',
                description:
                    'They needed a website update. Their business had grown and evolved and they saw the need for a website revamp.\n\nThey asked us for a redesign because they needed a website that was more aligned with their identity and matched the quality and professionalism of their work.',
            },
            {
                id: 'pg-3',
                title: 'The Solution',
                description: '', // TODO: falta data
            },
        ],
    },
];

export const CASE_STUDIES_HERO = {
    title: 'Case\nStudies',
    heroDescriptionText:
        "We let our work speaks for itself, I'll bet you'd be the next case study on our website.",
    bgImg: heroBgImage,
};

export const MENU_ITEMS: MenuItem[] = [
    {
        id: 0,
        label: 'Services',
        url: '/#services',
    },
    {
        id: 1,
        label: 'Case Studies',
        url: '/case-studies/',
    },
    {
        id: 2,
        label: 'About',
        url: '/about/',
    },
    {
        id: 3,
        label: 'Contact',
        url: '/#contact-us',
    },
];

export const SERVICES: { headline: string; subheadline: string; services: ServiceProps[] } = {
    headline: `Services<br />& Tech Stack<br />&#92;`,
    subheadline:
        'Our sweet spot: where strategic product design meets rock-solid technology, you’ll always find us playing with the latest trends and always shooting for perfection. So if you need an experienced team that can deliver top-of-the-art solutions every single time give us a call.',
    services: [
        {
            id: 1,
            name: 'UX & \n UI Design',
            description:
                'Give us an idea, and our designers bring it to life. We offer you state-of-the-art design according to your business needs, and also to increase the conversion rate and dwell time.',
            products: [
                { name: 'User personas' },
                { name: 'User flow' },
                { name: 'Visual design' },
                { name: 'User stories' },
                { name: 'Wireframes' },
                { name: 'Branding' },
            ],
            position: 'Left',
        },
        {
            id: 2,
            name: 'Web \n Development',
            description:
                'Whether you need a new web app, an e-commerce solution, or a back-office system, we’ve got you covered. Improve your business processes and increase profits through strategic web software development.',
            products: [
                { name: 'Web Applications' },
                { name: 'eCommerce' },
                { name: 'Websites' },
                { name: 'Web platforms' },
            ],
            position: 'Right',
        },
        {
            id: 3,
            name: 'Mobile \n Development',
            description:
                'Our mobile app solutions are solving problems and improving lives for millions of users across different industries: Finance, Entertainment, Education, Mass Media, Construction, IoT and more.',
            products: [{ name: 'iOS/Android apps' }, { name: 'Cross platform' }],
            position: 'Left',
        },
        {
            id: 4,
            name: 'Consulting',
            description:
                'Our collective experience has led us to realize key insights about the relationship between multiple businesses and technology, so if you need to define, improve or boost its role inside your organization, give us a call!',
            products: [
                { name: 'Businesses & Tech' },
                { name: 'External CTO' },
                { name: 'Best Practices' },
                { name: 'Scaling' },
            ],
            position: 'Right',
        },
    ],
};

export const HISTORYSLIDES: HistorySlide[] = [
    {
        id: 1,
        headline: '“We all have a past, a present, and a future.”',
    },
    {
        id: 2,
        headline: 'A Past...',
        body: 'The legend tells that our founding partners were the wizards behind the scenes for some amazing projects for great brands like:',
        brands: [
            { name: 'ComCast', img: ComCastImg },
            { name: 'The new york times', img: TheNewYorkTimesLogoImg },
            { name: 'Rofex', img: RofexImg },
            { name: 'Arte Unico', img: ArteUnicoImg },
        ],
    },
    {
        id: 3,
        headline: 'A Present...',
        body: 'Nowadays always find us crafting products for unique brands like:',
        brands: [
            { name: 'MadCave', img: MadCaveLogo },
            { name: 'Anthos', img: AnthosLogo },
            { name: 'Get Real', img: GetRealLogo },
            { name: 'Georgia Aquarium', img: AquariumLogo },
        ],
    },
    {
        id: 4,
        headline: 'A Future...',
        body: 'We envision a bright future creating amazing products for new clients, is that you?',
        cta: {
            label: 'Let’s Do It!',
            url: '#contact-us',
        },
    },
];

export const ABOUTPAGE = {
    title: 'About Us',
    heroDescriptionText:
        'We are the ones you call when you need someone on your corner, a team of people that will tell you what they think and not always what you want to hear. We pride ourselves on challenging your ideas and arriving at what’s best for your business while doing so, a true partner that will leverage technology to skyrocket your business.',
    imgSrc: '/images/about/main-hero.webp',
    imgAlt: 'image',
    bgImg: AboutHeroBgImg,
    phraseText: 'Perfect does not exist, but we strive to be as close as possible to that concept.',
    phraseDescriptionText:
        'As our name implies, iteration over our work even after is done is what makes us grow, learn and improve so that after each project we are closer to that goal, perfection.',
};

export const SOCIAL_MEDIA_LINKS = {
    linkedIn: 'https://www.linkedin.com/company/loop3studio/',
};
