import React, { FC, useState, useEffect, useRef, useContext } from 'react';
import { Link } from 'gatsby';
import { AppContext, AppContextProps } from '../../store/context';
import gsap, { Power2 } from 'gsap';
import { Media } from '../Media/Media';
import classNames from 'classnames';
import LogoImg from '../../assets/images/logo-loop3.svg';
import Drawer from '../Drawer/Drawer';
import Cta from './Cta/Cta';
import Hamburger from './Hamburger/Hamburger';

import './Navbar.scss';

type NavbarProps = {
    onDrawerChange?: (state: boolean) => void;
    location: any;
};

const Navbar = ({ onDrawerChange, location }: NavbarProps) => {
    const { lightTheme, currentPage, setNavbarHeight } = useContext(AppContext) as AppContextProps;
    const [isDrawerActive, setIsDrawerActive] = useState(false);
    const [isNavbarHidden, setIsNavbarHidden] = useState<boolean>(false);
    const [prevScrollpos, setPrevScrollpos] = useState<number>(0);
    const navbarRef = useRef<HTMLDivElement>(null);
    const logoTl = useRef<any>(null);
    const q = gsap.utils.selector(navbarRef);

    useEffect(() => {
        logoTl.current = gsap.timeline({ paused: true }).to(q('.navbar__logo'), {
            opacity: 0,
            duration: 1,
            ease: Power2.easeInOut,
        });

        if (navbarRef.current) {
            setNavbarHeight(navbarRef.current.offsetHeight);
        }

        window.addEventListener('resize', () => {
            if (navbarRef.current) {
                setNavbarHeight(navbarRef.current.offsetHeight);
            }
        });
    }, []);

    useEffect(() => {
        if (isDrawerActive) {
            logoTl.current.play();
        } else {
            setTimeout(() => {
                logoTl.current.reverse();
            }, 500);
        }
        return onDrawerChange && onDrawerChange(isDrawerActive);
    }, [isDrawerActive]);

    const scrollNavChange = () => {
        if (typeof window === undefined) return;

        const currentScrollPos = window.pageYOffset;

        if (currentScrollPos > prevScrollpos) {
            setIsNavbarHidden(true);
        } else {
            setIsNavbarHidden(false);
        }
        setPrevScrollpos(currentScrollPos);
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollNavChange);
        return () => window.removeEventListener('scroll', scrollNavChange);
    }, [prevScrollpos]);

    return (
        <>
            <nav
                id="navbar"
                ref={navbarRef}
                data-scroll
                className={classNames(
                    'navbar header',
                    { 'navbar--hidden': isNavbarHidden && window.scrollY > 450 },
                    {
                        'animated slideInDownNavbar':
                            currentPage === 'Home' && !location.hash.includes('#'),
                    },
                    { 'navbar--overlay-active': isDrawerActive },
                    { 'navbar--light-theme': lightTheme },
                )}>
                <div className="navbar__wrapper">
                    <div className="navbar__logo logo">
                        <Link to={'/'} style={{ display: 'block' }}>
                            <Media source={LogoImg} className={'logo__image'} alt={'loop3-logo'} />
                        </Link>
                    </div>
                    <div className="navbar__items">
                        <Cta className="navbar__cta" />
                        <Hamburger
                            onClick={() => {
                                setIsDrawerActive(true);
                            }}
                        />
                    </div>
                </div>
            </nav>
            <Drawer
                active={isDrawerActive}
                onClose={() => {
                    setIsDrawerActive(false);
                }}
            />
        </>
    );
};

export default Navbar;
