import React, { FC } from 'react';
import HamburgerIcon from '../../../assets/icons/hamburger.inline.svg';

import './Hamburger.scss';

export type HamburgerProps = {
    onClick?: () => void;
};

const Hamburger: FC<HamburgerProps> = props => {
    return (
        <div className="hamburguer" onClick={() => props.onClick && props.onClick()}>
            <HamburgerIcon />
            <ul className="hamburguer__lines">
                <li className="hamburguer__line"></li>
                <li className="hamburguer__line"></li>
                <li className="hamburguer__line"></li>
            </ul>
        </div>
    );
};

export default Hamburger;
